<template>
  <v-card flat>
    <v-card-text>
      <change-organization-name />
      <template v-if="organization.remainingTrialDays >= 0">
        <v-card-title>
          Free Trial
        </v-card-title>
        <v-card-subtitle>
          You have <strong>{{ organization.remainingTrialDays }}</strong> days remaining on your free trial display. Once the trial is over, your display using the trial plan will revert to a free plan.
        </v-card-subtitle>
        <v-alert
          v-if="!organization.isUsingTrialDisplay"
          type="warning"
          outlined
          dense
        >
          You haven't paired a display to your free plan yet.
        </v-alert>
      </template>
    </v-card-text>
    <!-- subscription -->
    <template v-if="!organization.stripeCustomerId || !customer || !customer.subscriptions || !customer.subscriptions.data.length">
      <v-divider />
      <v-card-text>
        <v-card-title>
          Start Your Subscription
        </v-card-title>
        <v-card-subtitle>
          Add as many display plans as you would like and fill out the forms below to start a paid subscription for your organization.
        </v-card-subtitle>
        <validation-observer
          v-slot="{ invalid }"
          slim
        >
          <subscription
            ref="subscription"
            start
            :customer="newCustomer"
            @items="items = $event"
            @upcoming="upcoming = $event"
          />
          <field-card
            title="Payment Details"
            subtitle=""
            outlined
            class="mx-auto"
          >
            <add-card
              start
              @payment-method="paymentMethod = $event"
            />
          </field-card>
          <update-billing-details
            start
            @customer="newCustomer = $event"
          />
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-if="upcoming"
              x-large
              color="primary"
              :disabled="subscribing || invalid || !paymentMethod || !paymentMethod.id"
              :loading="subscribing"
              @click="submit"
            >
              Confirm {{ upcoming.total | formatPrice }} payment
            </v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card-text>
    </template>
    <v-overlay v-if="subscribing">
      <card-loader message="Please hold on..." />
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ChangeOrganizationName from '@backend/components/auth/ChangeOrganizationName'
import Subscription from '@backend/pages/settings/subscription'
import UpdateBillingDetails from '@backend/components/UpdateBillingDetails'
import AddCard from '@backend/components/AddCard'
export default {
  name: 'SettingsIndex',
  components: {
    ChangeOrganizationName,
    Subscription,
    AddCard,
    UpdateBillingDetails
  },
  props: {
    customer: {
      type: Object,
      default: () => null
    }
  },
  data: () => ({
    newCustomer: {
      address: {
        country: ''
      }
    },
    items: [],
    paymentMethod: null,
    upcoming: null,
    subscribing: false
  }),
  computed: {
    ...mapGetters(['organization'])
  },
  watch: {
    'newCustomer.address.country'(country) {
      this.$nextTick(() => {
        this.$refs.subscription.previewChanges(true)
      })
    }
  },
  methods: {
    async submit() {
      this.subscribing = true
      try {
        const { data, error } = await this.$axios.post(`${this.$apiUrl}/stripe/customer`, {
          organizationId: this.organization.id,
          customer: this.newCustomer,
          items: this.items,
          payment_method: this.paymentMethod.id
        })
        if (error) {
          return this.error(error)
        }
        const paymentIntent = data.latest_invoice.payment_intent
        if (!paymentIntent) {
          return this.success()
        }
        if (paymentIntent.status !== 'requires_action') {
          return this.success()
        }
        const response = await this.$stripe.confirmCardPayment(paymentIntent.client_secret)
        if (!response.error) {
          return this.success()
        }
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
        this.error(response.error)
      } catch (error) {
        this.error(error)
      }
    },
    error(error) {
      this.$store.dispatch('newSnackbar', { type: 'error', message: error.message ? error.message : error })
      this.subscribing = false
    },
    success() {
      this.$emit('refresh')
      this.$store.dispatch('newSnackbar', { type: 'success', message: 'Your payment was successfully processed.' })
      this.subscribing = false
      this.$router.push({ name: 'subscription' })
    }
  }
}
</script>
