<template>
  <validation-observer v-slot="{ invalid, dirty }" slim>
    <v-card
      :flat="start"
    >
      <template v-if="!start">
        <v-card-title>
          Update Address
          <v-spacer />
          <v-btn icon @click="$emit('cancel')">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
      </template>
      <v-card-text :class="{ 'pa-0': start }">
        <v-alert v-if="!start" type="info" text>
          This address appears on your monthly invoice and should be the legal address of your home or business. Changing your address may update your tax location.
        </v-alert>
        <v-alert v-if="error" type="error" outlined>
          {{ error }}
        </v-alert>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Country"
            >
              <v-autocomplete
                v-model="customer.address.country"
                :items="countries"
                :error-messages="errors"
                label="Country*"
                clearable
                outlined
                item-text="name"
                item-value="isoCode"
                hide-details
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Business Name"
            >
              <v-text-field
                v-model="customer.name"
                outlined
                label="Business Name*"
                required
                :error-messages="errors"
                hide-details
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Address Line 1"
            >
              <v-text-field
                v-model="customer.address.line1"
                outlined
                label="Address Line 1*"
                required
                :error-messages="errors"
                hide-details
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="customer.address.line2"
              outlined
              label="Address Line 2"
              hide-details
              required
            />
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="City"
            >
              <v-text-field
                v-model="customer.address.city"
                outlined
                hint="Enter your city, district, suburb, town or village here."
                label="City*"
                :error-messages="errors"
                hide-details
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="customer.address.state"
              outlined
              label="State / Province / Region"
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              name="Zip / Postal Code"
            >
              <v-text-field
                v-model="customer.address.postal_code"
                outlined
                label="Zip / Postal Code*"
                :error-messages="errors"
                hide-details
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="customer.phone"
              outlined
              label="Phone Number"
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required|email"
              name="Email Address"
            >
              <v-text-field
                v-model="customer.email"
                outlined
                label="Email Address*"
                hint="Invoices will be sent to this email address."
                persistent-hint
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <template v-if="!start">
        <v-divider />
        <v-card-actions>
          <v-btn
            color="primary"
            block
            x-large
            :disabled="!dirty || invalid"
            @click="saveBillingDetails"
          >
            Save Billing Details
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'UpdateBillingDetails',
  props: {
    start: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    error: null,
    customer: {
      name: '',
      phone: '',
      email: '',
      address: {
        city: '',
        country: '', // 2-letter country code.
        line1: '', // * required
        line2: '',
        postal_code: '',
        state: ''
      }
    },
    countries: []
  }),
  computed: {
    ...mapGetters(['organization', 'user'])
  },
  watch: {
    'customer.email': {
      handler(val) {
        if (!val) {
          this.customer.email = this.user.email
        }
      },
      immediate: true
    },
    customer: {
      handler(val) {
        this.start && this.$emit('customer', val)
      },
      deep: true
    }
  },
  async beforeMount() {
    const countries = await this.$db.collection('config').doc('countries').get()
    this.countries = countries.data().list
    const { stripeCustomerId } = this.organization
    if (!stripeCustomerId) {
      return
    }
    try {
      const { data } = await this.$axios.get(`${this.$apiUrl}/stripe/customer`, {
        params: { stripeCustomerId }
      })
      if (!data.address) {
        data.address = {}
      }
      this.customer = data
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async saveBillingDetails() {
      try {
        await this.$axios.post(`${this.$apiUrl}/stripe/customer`, {
          organizationId: this.organization.id,
          customer: this.customer
        })
        this.$emit('done')
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
