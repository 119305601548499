<template>
  <validation-observer
    ref="form"
    v-slot="{ valid, dirty }"
    tag="form"
    @submit.prevent="submit"
  >
    <v-alert
      v-if="error"
      dense
      outlined
      border="left"
      type="error"
      class="mb-0"
    >
      {{ error.message }}
    </v-alert>
    <v-list-item class="wrapped">
      <v-list-item-content>
        <validation-provider
          v-slot="{ errors }"
          rules="required|min:3"
          name="Organization Name"
        >
          <v-text-field
            v-model="name"
            name="name"
            label="Organization Name"
            outlined
            autocomplete="off"
            prepend-icon="mdi-account"
            :error-messages="errors"
          />
        </validation-provider>
      </v-list-item-content>
      <v-list-item-action class="align-self-start pt-0 pt-sm-1">
        <v-btn
          large
          outlined
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="!dirty || error !== null || !valid || loading"
        >
          Update
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'ChangeOrganizationName',
  data: () => ({
    name: '',
    loading: false,
    error: null
  }),
  computed: {
    ...mapGetters(['user', 'organization'])
  },
  mounted() {
    this.name = this.organization.name
  },
  methods: {
    async submit() {
      const form = this.$refs.form
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      this.loading = true
      try {
        await this.$db.collection('organizations').doc(this.organization.id).update({
          name: this.name
        })
        this.loading = false
      } catch (error) {
        this.error = error
        this.loading = false
      }
    }
  }
}
</script>
