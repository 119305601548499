<template>
  <validation-observer
    ref="stripeForm"
    tag="form"
    @submit.prevent="submit"
  >
    <v-card
      :flat="start"
    >
      <template v-if="!start">
        <v-card-title>
          Add a card
          <v-spacer />
          <v-btn icon @click="$emit('done')">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
      </template>
      <v-card-text class="px-0 px-sm-3">
        <!-- Used to display form errors. -->
        <v-alert
          v-if="error"
          type="error"
          dense
          outlined
          border="left"
        >
          {{ error }}
        </v-alert>
        <v-alert v-else-if="!start" dense type="info" color="primary" outlined>
          All major credit / debit cards are accepted.
        </v-alert>
        <div
          id="card-element"
          class="py-3 px-1 mt-2 v-card v-card--outlined theme--light"
          style="border-color: rgba(0, 0, 0, 0.38); max-width: 500px;"
        />
        <v-card-text v-if="!start" class="justify-end">
          <v-checkbox v-model="makeDefault" label="Make this my default payment method." />
        </v-card-text>
      </v-card-text>
      <template v-if="!start">
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            type="submit"
            :loading="loading"
            :disabled="loading"
            color="primary"
            outlined
          >
            Save Card
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AddCard',
  props: {
    start: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => ({
    cardElement: null,
    makeDefault: true,
    loading: false,
    error: ''
  }),
  computed: {
    ...mapGetters(['user', 'organization'])
  },
  mounted() {
    const elements = this.$stripe.elements()
    const style = {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }
    this.cardElement = elements.create('card', { style, hidePostalCode: false })
    this.cardElement.mount('#card-element')
    // Handle real-time validation errors from the card Element.
    this.cardElement.addEventListener('change', (event) => {
      this.error = event.error ? event.error.message : ''
      this.start && this.$emit('payment-method', null)
    })
    this.start && this.cardElement.addEventListener('blur', this.createPaymentMethod)
  },
  methods: {
    async createPaymentMethod() {
      const { paymentMethod, error } = await this.$stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
        billing_details: {
          email: this.user.email
        }
      })
      if (error) {
        this.error = error.message
        return
      }
      this.start && this.$emit('payment-method', paymentMethod)
      return paymentMethod
    },
    // Handle form submission.
    async submit() {
      this.error = ''
      this.loading = true
      const paymentMethod = await this.createPaymentMethod()
      if (this.error) {
        this.loading = false
        return
      }
      const { stripeCustomerId } = this.organization
      const form = {
        // email: paymentMethod.billing_details.email,
        payment_method: paymentMethod.id,
        stripeCustomerId,
        makeDefault: this.makeDefault
      }
      try {
        await this.$axios.post(`${this.$apiUrl}/stripe/add-payment-method`, form)
        this.$store.dispatch('newSnackbar', { type: 'success', message: 'Card added.' })
        this.loading = false
        this.$emit('done')
      } catch (error) {
        this.$store.dispatch('newSnackbar', { type: 'error', message: 'Card could not be added.', error })
        this.error = error
        this.loading = false
      }
    }
  }
}
</script>
